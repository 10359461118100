export const FETCH_SHOPINFO = 'FETCH_SHOPINFO';
export const UPDATE_SHOP_INFO = 'UPDATE_SHOP_INFO';

export const SHOW_TOAST = 'SHOW_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const FETCH_PLANS = 'FETCH_PLANS';
export const GET_ESTIMATED_CAMPAIGN = 'GET_ESTIMATED_CAMPAIGN';
export const DELETE_ESTIMATED_CAMPAIGN = 'DELETE_ESTIMATED_CAMPAIGN';
export const UPDATE_CAN_ADD_ACTION = 'UPDATE_CAN_ADD_ACTION';
export const UPDATE_DASHBOARD_REPORT = 'UPDATE_DASHBOARD_REPORT';
export const SET_STORE_PRODUCTS = 'SET_STORE_PRODUCTS';
export const SET_STORE_COLLECTIONS = 'SET_STORE_COLLECTIONS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const SET_CAMPAIGNS_LOADING = 'SET_CAMPAIGNS_LOADING';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTERED_CAMPAIGNS = 'SET_FILTERED_CAMPAIGNS';
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN';
export const SET_ACCESSIBILITY = 'SET_ACCESSIBILITY';
export const SET_ACCESSIBILITY_AND_CURRENT_PLAN = 'SET_ACCESSIBILITY_AND_CURRENT_PLAN';
export const DELETE_ALL_ESTIMATED_PRODUCTS = 'DELETE_ALL_ESTIMATED_PRODUCTS';
export const SET_HELP_PAGE = 'SET_HELP_PAGE';
export const SET_FAQ_LIST = 'SET_FAQ_LIST';
export const SET_LOADING = 'SET_LOADING';
export const SET_FEED_BACK_GIVEN_PAGE = 'SET_FEED_BACK_GIVEN_PAGE';
export const SET_PALNS_AND_DISCOUNT = 'SET_PALNS_AND_DISCOUNT';
export const SET_DISCOUNT_LOADING = 'SET_DISCOUNT_LOADING';
export const SET_DISCOUNT_ERROR = 'SET_DISCOUNT_ERROR';
export const CLEAR_DISCOUNT_ERROR = 'CLEAR_DISCOUNT_ERROR';
export const SET_PALNS_WITHOUT_DISCOUNT = 'SET_PALNS_WITHOUT_DISCOUNT';
export const UPDATE_DASHBOARD_REPORT_LOADING = 'UPDATE_DASHBOARD_REPORT_LOADING';
export const UPDATE_DASHBOARD_REPORT_FAILED = 'UPDATE_DASHBOARD_REPORT_FAILED';
export const SET_FILTER = 'SET_FILTER';
export const SET_ERRORS = 'SET_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const REMOVE_ERROR_WITH_VALUE_IN_ARRAY = 'REMOVE_ERROR_WITH_VALUE_IN_ARRAY';
export const SET_SETTING = 'SET_SETTING';
export const UPGRADE_MODAL_OPEN = 'UPGRADE_MODAL_OPEN';
export const UPGRADE_MODAL_CLOSE = 'UPGRADE_MODAL_CLOSE';
export const SET_PREVIEW_CAMPAIGN = 'SET_PREVIEW_CAMPAIGN';
export const SET_PREVIEW_CAMPAIGN_DATA = 'SET_PREVIEW_CAMPAIGN_DATA';
