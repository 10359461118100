"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./activateCampaign200"), exports);
__exportStar(require("./activateCampaign200Data"), exports);
__exportStar(require("./baseCampaignResponseDto"), exports);
__exportStar(require("./baseCampaignResponseDtoGeneralDiscountCustomCent"), exports);
__exportStar(require("./baseCampaignResponseDtoGeneralDiscountPriceChangeMode"), exports);
__exportStar(require("./baseCampaignResponseDtoGeneralDiscountType"), exports);
__exportStar(require("./baseCampaignResponseDtoProductExclusionType"), exports);
__exportStar(require("./baseCampaignResponseDtoProductInclusionType"), exports);
__exportStar(require("./baseCampaignResponseDtoStatus"), exports);
__exportStar(require("./baseCampaignResponseDtoType"), exports);
__exportStar(require("./baseCampaignResponseDtoVolumeDiscountMinimumApplyType"), exports);
__exportStar(require("./baseCampaignResponseDtoVolumeDiscountMinimumType"), exports);
__exportStar(require("./baseCampaignResponseDtoVolumeDiscountType"), exports);
__exportStar(require("./campaignCollectionDto"), exports);
__exportStar(require("./campaignCollectionDtoMeta"), exports);
__exportStar(require("./campaignControllerProgressSseParams"), exports);
__exportStar(require("./campaignDto"), exports);
__exportStar(require("./campaignDtoGeneralDiscountCustomCent"), exports);
__exportStar(require("./campaignDtoGeneralDiscountPriceChangeMode"), exports);
__exportStar(require("./campaignDtoGeneralDiscountType"), exports);
__exportStar(require("./campaignDtoProductExclusionType"), exports);
__exportStar(require("./campaignDtoProductInclusionType"), exports);
__exportStar(require("./campaignDtoType"), exports);
__exportStar(require("./campaignDtoVolumeDiscountMinimumApplyType"), exports);
__exportStar(require("./campaignDtoVolumeDiscountMinimumType"), exports);
__exportStar(require("./campaignDtoVolumeDiscountType"), exports);
__exportStar(require("./campaignPriceRangeDto"), exports);
__exportStar(require("./campaignProductDto"), exports);
__exportStar(require("./campaignProductDtoMeta"), exports);
__exportStar(require("./campaignProductTypeDto"), exports);
__exportStar(require("./campaignResponseDto"), exports);
__exportStar(require("./campaignResponseDtoGeneralDiscountCustomCent"), exports);
__exportStar(require("./campaignResponseDtoGeneralDiscountPriceChangeMode"), exports);
__exportStar(require("./campaignResponseDtoGeneralDiscountType"), exports);
__exportStar(require("./campaignResponseDtoProductExclusionType"), exports);
__exportStar(require("./campaignResponseDtoProductInclusionType"), exports);
__exportStar(require("./campaignResponseDtoStatus"), exports);
__exportStar(require("./campaignResponseDtoType"), exports);
__exportStar(require("./campaignResponseDtoVolumeDiscountMinimumApplyType"), exports);
__exportStar(require("./campaignResponseDtoVolumeDiscountMinimumType"), exports);
__exportStar(require("./campaignResponseDtoVolumeDiscountType"), exports);
__exportStar(require("./campaignStatusDetail"), exports);
__exportStar(require("./campaignStatusDetail200"), exports);
__exportStar(require("./campaignStatusDetailStatus"), exports);
__exportStar(require("./campaignTagDto"), exports);
__exportStar(require("./campaignVendorDto"), exports);
__exportStar(require("./cancelCampaign200"), exports);
__exportStar(require("./cancelCampaign200Data"), exports);
__exportStar(require("./changeDiscountNotes200"), exports);
__exportStar(require("./changeDiscountNotes200Data"), exports);
__exportStar(require("./changeDiscountNotesBody"), exports);
__exportStar(require("./createAndActivateCampaign200"), exports);
__exportStar(require("./createCampaign200"), exports);
__exportStar(require("./deactivateCampaign200"), exports);
__exportStar(require("./deactivateCampaign200Data"), exports);
__exportStar(require("./deleteCampaign200"), exports);
__exportStar(require("./deleteCampaign200Data"), exports);
__exportStar(require("./discountCodeExists200"), exports);
__exportStar(require("./discountCodeExistsParams"), exports);
__exportStar(require("./discountDto"), exports);
__exportStar(require("./discountDtoPeriod"), exports);
__exportStar(require("./estimateRequest200"), exports);
__exportStar(require("./estimateRequest200Data"), exports);
__exportStar(require("./getActiveCampaignRevenueReport200"), exports);
__exportStar(require("./getCampaign200"), exports);
__exportStar(require("./getCampaignProducts200"), exports);
__exportStar(require("./getCampaignProducts200DataItem"), exports);
__exportStar(require("./getCampaignProducts200DataItemImagesItem"), exports);
__exportStar(require("./getCampaignProducts200DataItemVariantsItem"), exports);
__exportStar(require("./getCampaignProductsParams"), exports);
__exportStar(require("./getCampaigns200"), exports);
__exportStar(require("./getCampaignsParams"), exports);
__exportStar(require("./getCampaignsSortBy"), exports);
__exportStar(require("./getCampaignsSortDirection"), exports);
__exportStar(require("./getEstimateProducts200"), exports);
__exportStar(require("./getEstimateProducts200DataItem"), exports);
__exportStar(require("./getEstimateProducts200DataItemImagesItem"), exports);
__exportStar(require("./getEstimateProducts200DataItemVariantsItem"), exports);
__exportStar(require("./getEstimateProducts200Status"), exports);
__exportStar(require("./getEstimateProductsParams"), exports);
__exportStar(require("./getPlanUsage200"), exports);
__exportStar(require("./getPlans200"), exports);
__exportStar(require("./getPlansParams"), exports);
__exportStar(require("./getProductTags200"), exports);
__exportStar(require("./getProductTypes200"), exports);
__exportStar(require("./getSettings200"), exports);
__exportStar(require("./getShopInfo200"), exports);
__exportStar(require("./getShopStorage200"), exports);
__exportStar(require("./getShopStorageParams"), exports);
__exportStar(require("./getTopFiveCampaigns200"), exports);
__exportStar(require("./getVendors200"), exports);
__exportStar(require("./orderReport200"), exports);
__exportStar(require("./orderReportDto"), exports);
__exportStar(require("./orderReportParams"), exports);
__exportStar(require("./pageInfo"), exports);
__exportStar(require("./planDto"), exports);
__exportStar(require("./planDtoCurrentPlanPeriod"), exports);
__exportStar(require("./planDtoPermissionsItem"), exports);
__exportStar(require("./planUsageDto"), exports);
__exportStar(require("./planUsageDtoCurrentPlanPeriod"), exports);
__exportStar(require("./productCountdownSettingsDto"), exports);
__exportStar(require("./productCountdownSettingsDtoCountDownType"), exports);
__exportStar(require("./sendAnonymsSupportMail200"), exports);
__exportStar(require("./sendAnonymsSupportMail200Data"), exports);
__exportStar(require("./sendSupportMail200"), exports);
__exportStar(require("./sendSupportMail200Data"), exports);
__exportStar(require("./setProductCountdownSettings200"), exports);
__exportStar(require("./setProductCountdownSettings200Data"), exports);
__exportStar(require("./setSettings200"), exports);
__exportStar(require("./setShopStorage200"), exports);
__exportStar(require("./settingsDto"), exports);
__exportStar(require("./settingsDtoPreventDiscountStatus"), exports);
__exportStar(require("./shopInfoDto"), exports);
__exportStar(require("./shopInfoDtoCurrentPlan"), exports);
__exportStar(require("./shopIsInstalled200"), exports);
__exportStar(require("./shopIsInstalledParams"), exports);
__exportStar(require("./shopStorageDto"), exports);
__exportStar(require("./shopStorageDtoValue"), exports);
__exportStar(require("./supportTicketDto"), exports);
__exportStar(require("./supportTicketDtoType"), exports);
__exportStar(require("./tier"), exports);
__exportStar(require("./updateAndActivateCampaign200"), exports);
__exportStar(require("./updateCampaign200"), exports);
__exportStar(require("./upgradePlan200"), exports);
__exportStar(require("./upgradePlan200Data"), exports);
__exportStar(require("./upgradePlanDto"), exports);
__exportStar(require("./upgradePlanDtoPeriod"), exports);
