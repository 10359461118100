import { combineReducers } from 'redux';

import { shopReducer } from './reducers/shop';
import { toastReducer } from './reducers/toast';
import { plansReducer } from './reducers/plans';
import { campaignsReducer } from './reducers/campaigns';
import { resourceReducer } from './reducers/resource';
import { helpReducer } from './reducers/help';
import { campaignReducer } from './reducers/campaign';
import { settingReducer } from './reducers/setting';
import { previewReducer } from './reducers/preview';

export const rootReducer = (state, action) => {
  return combineReducers({
    shop: shopReducer,
    toast: toastReducer,
    plans: plansReducer,
    campaigns: campaignsReducer,
    resource: resourceReducer,
    help: helpReducer,
    campaign: campaignReducer,
    setting: settingReducer,
    preview: previewReducer,
  })(state, action);
};
