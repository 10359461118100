"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShopStorage = exports.setShopStorage = exports.setProductCountdownSettings = exports.changeDiscountNotes = exports.getVendors = exports.getProductTags = exports.getProductTypes = exports.getShopInfo = exports.shopIsInstalled = void 0;
const fetchInstance_1 = require("../../../utils/fetchInstance");
/**
* @summary Check shop exists and installed
*/
const shopIsInstalled = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/isInstalled`, method: 'get',
        params
    });
};
exports.shopIsInstalled = shopIsInstalled;
/**
* @summary Get shop info
*/
const getShopInfo = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/info`, method: 'get'
    });
};
exports.getShopInfo = getShopInfo;
/**
* @summary Get list of product types
*/
const getProductTypes = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/productTypes`, method: 'get'
    });
};
exports.getProductTypes = getProductTypes;
/**
* @summary Get list of Product Tags
*/
const getProductTags = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/productTags`, method: 'get'
    });
};
exports.getProductTags = getProductTags;
/**
* @summary Get list Vendors
*/
const getVendors = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/vendors`, method: 'get'
    });
};
exports.getVendors = getVendors;
/**
* @summary Change discount notes
*/
const changeDiscountNotes = (changeDiscountNotesBody) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/discountNotes`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: changeDiscountNotesBody
    });
};
exports.changeDiscountNotes = changeDiscountNotes;
/**
* @summary Set product discount settings
*/
const setProductCountdownSettings = (productCountdownSettingsDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/productCountdownSettings`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: productCountdownSettingsDto
    });
};
exports.setProductCountdownSettings = setProductCountdownSettings;
/**
* @summary Set list of key value storage
*/
const setShopStorage = (shopStorageDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/storage`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: shopStorageDto
    });
};
exports.setShopStorage = setShopStorage;
/**
* @summary get list of key value storage with filter
*/
const getShopStorage = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/shops/storage`, method: 'get',
        params
    });
};
exports.getShopStorage = getShopStorage;
