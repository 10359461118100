import { isEmpty } from 'lodash';

const initialProductCountdownSettings = {
  containsProductTitle: true,
  afterProductTitleText: 'SALE ENDS IN',
  beforeProductTitleText: '',
  footerText: '',
  daysLabel: 'Days',
  hoursLabel: 'Hours',
  minutesLabel: 'Minutes',
  secondsLabel: 'Seconds',
  textColor: '#333333',
  digitColor: '#ffffff',
  digitBackgroundColor: '#212b36',
  progressBarColor: '#5d6dc2',
  progressBarBorderColor: '#808080',
};

export function getShopInfoAdaptor(shopInfo, onboard) {
  var onboardingData = false;
  var filteredOnboard = onboard.filter((onboard) => onboard.key == 'onboardingFirstCampaign');
  if (filteredOnboard.length > 0) {
    onboardingData = filteredOnboard[0].value;
  }
  var onboardingActivateCampaignData = false;
  var filteredOnboardingActivateCampaign = onboard.filter(
    (onboard) => onboard.key == 'onboardingActivateCampaign'
  );
  if (filteredOnboardingActivateCampaign.length > 0) {
    onboardingActivateCampaignData = filteredOnboardingActivateCampaign[0].value;
  }
  var onboardingNeedUpgradeCampaignData = false;
  var filteredOnboardingNeedUpgradeCampaign = onboard.filter(
    (onboard) => onboard.key == 'onboardingNeedUpgradeCampaign'
  );
  if (filteredOnboardingNeedUpgradeCampaign.length > 0) {
    onboardingNeedUpgradeCampaignData = filteredOnboardingNeedUpgradeCampaign[0].value;
  }
  var onboardingDismissData = false;
  var filteredOnboardingDismiss = onboard.filter((onboard) => onboard.key == 'onboardingDismiss');
  if (filteredOnboardingDismiss.length > 0) {
    onboardingDismissData = filteredOnboardingDismiss[0].value;
  }
  var onboardingThemeExtensionData = false;
  var filteredOnboardingThemeExtension = onboard.filter(
    (onboard) => onboard.key == 'onboardingThemeExtension'
  );
  if (filteredOnboardingThemeExtension.length > 0) {
    onboardingThemeExtensionData = filteredOnboardingThemeExtension[0].value;
  }
  var onboardingThemeExtension2Data = false;
  var filteredOnboardingThemeExtension2 = onboard.filter(
    (onboard) => onboard.key == 'onboardingThemeExtension2'
  );
  if (filteredOnboardingThemeExtension2.length > 0) {
    onboardingThemeExtension2Data = filteredOnboardingThemeExtension2[0].value;
  }

  return {
    id: shopInfo?.id,
    customer_email: shopInfo.ownerEmail,
    domain: shopInfo.shopifyDomain,
    shop_owner: shopInfo.ownerName,
    ownerEmail: shopInfo.ownerEmail,
    name: shopInfo.name,
    phone: shopInfo.ownerPhoneNumber,
    shopify_domain: shopInfo.shopifyDomain,
    contact_email: shopInfo.ownerEmail,
    myshopify_domain: shopInfo.shopifyDomain,
    money_format: shopInfo.moneyFormat,
    money_with_currency_format: shopInfo.moneyWithCurrencyFormat,
    iana_timezone: shopInfo.ianaTimezone,
    timezone: shopInfo.timezone,
    currency: shopInfo.currency,
    shopify_plan: shopInfo.planName,
    country: shopInfo.country,
    isTest: shopInfo.isTest,
    created_at: shopInfo.createdAt,
    primary_locale: shopInfo.primaryLocale,
    currentPlan: {
      currentPlanId: shopInfo.currentPlan?.id,
      currentPlanPeriod: shopInfo.currentPlanPeriod === 'monthly' ? 0 : 1,
      CurrentPlanUser: '',
      planName: shopInfo.currentPlan?.name,
    },
    // TODO: fake data
    trialEligible: true,
    permissions: {
      can_set_schedule: shopInfo.currentPlan.permissions.includes('campaign_start_schedule'),
      can_set_exclude_product: shopInfo.currentPlan.permissions.includes(
        'campaign_exclude_products'
      ),
      can_set_discount_type: shopInfo.currentPlan.permissions.includes('campaign_start_schedule'),
      can_set_onFly_campaign: true,
    },
    canAddCampaign: true,
    onboardingFirstCampaignPassed: onboardingData,
    onboardingActivateCampaignPassed: onboardingActivateCampaignData,
    onboardingNeedUpgradeCampaign: onboardingNeedUpgradeCampaignData,
    onboardingThemeExtension: onboardingThemeExtensionData,
    onboardingThemeExtension2: onboardingThemeExtension2Data,
    onboardingDismiss: onboardingDismissData,
    onboardingFirstCampaign: onboardingData,
    onboardingActivateCampaign: onboardingActivateCampaignData,
    functionOrderNote: shopInfo.functionOrderNote,
    functionProductNote: shopInfo.functionProductNote,
    productCountdownSettings: isEmpty(shopInfo.productCountdownSettings)
      ? initialProductCountdownSettings
      : shopInfo.productCountdownSettings,
    notification: {
      theme_extension: {
        show: true,
        count: 0,
      },
    },
  };
}
